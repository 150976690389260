<template>
  <div class="list" :style="showghs == 1 ? 'padding-top:120px;' : ''" ref="list">
    <!-- 头部 -->
    <van-nav-bar
      @click-left="onClickLeft"
      class="title"
      :border="false"
    >
      <template #title>
        <van-search
          ref="searchinput"
          shape="round"
          placeholder="请输入关键词"
          v-model="keywords"
          @focus="inputFocus"
          @blur="inputBlur"
          @input="inputText"
          @search="enterSearch"
          v-if="showghs == 0"
        >
        </van-search>
        <div v-if="showghs == 1">{{ghsInfo.merchname}}</div>
      </template>
      <template #left>
        <van-icon name="arrow-left" color="#333" size="20" />
      </template>
      <template #right>
        <van-icon @click="onClickRight" v-show="!showSearchBtn" :name="listType == 1 ? 'bars' : 'apps-o'"></van-icon>
        <div class="search_text" v-show="showSearchBtn" @click="onSearch">搜索</div>
      </template>
    </van-nav-bar>
    <!-- 筛选 -->
    <div v-if="showghs == 0" class="list_per">
      <van-grid :column-num="3" :border="false">
        <van-grid-item @click="sortList(0)">
          <template #text>
            <div :class="sort == 0 ? 'sort_list red' : 'sort_list'">新品</div>
          </template>
        </van-grid-item>
        <van-grid-item @click="sortList(1)">
          <template #text>
            <div class="sort_list">
              <span :class="sort == 1 || sort == 2 ? 'red' : ''">销量</span>
              <span class="sort_asc">
                <van-icon :class="sort == 1 ? 'red' : ''" name="arrow-up" />
                <van-icon :class="sort == 2 ? 'red' : ''" name="arrow-down" />
              </span>
            </div>
          </template>
        </van-grid-item>
        <van-grid-item @click="sortList(2)">
          <template #text>
            <div class="sort_list">
              <span :class="sort == 3 || sort == 4 ? 'red' : ''">价格</span>
              <span class="sort_asc">
                <van-icon :class="sort == 3 ? 'red' : ''" name="arrow-up" />
                <van-icon :class="sort == 4 ? 'red' : ''" name="arrow-down" />
              </span>
            </div>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div v-if="showghs == 1" class="list_per_ghs">
      <van-cell center :border="false">
        <template #icon>
          <van-image
            :src="ghsInfo.logo"
            lazy-load
            style="width: 40px; height: 40px; display: block;border-radius: 4px;"
            @click="goghsZiZhi(ghsInfo.id)"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </template>
        <template #title>
          <div @click="goghsZiZhi(ghsInfo.id)">
            {{ghsInfo.merchname}} <van-icon name="arrow" />
          </div>
        </template>
        <template #label>
          销量{{ghsInfo.num_salse}}  共{{ghsInfo.num_goods}}款商品
        </template>
        <template #right-icon>
          <van-button icon="share-o" size="small" round type="danger" @click="onShare">分享</van-button>
        </template>
      </van-cell>
    </div>
    <!-- 列表 竖版 -->
    <div class="list_content" v-show="listType == 1 ? true : false">
      <van-list
        v-model="goodsloading"
        :finished="goodsfinished"
        :finished-text="!kongshow && goodsfinished ? '没有更多了' : ''"
        :immediate-check="false"
        @load="getGoodsList"
      >
        <van-grid :gutter="10" :column-num="2" :border="false">
          <van-grid-item v-for="(item,index) in goods" :key="item.id" :data-index="index" :data-id="item.id" @click="details(item.id)">
            <template #icon>
              <van-image
                :src="item.thumb"
                :style="'width:' + (screenWidth - 30) / 2 + 'px;height:' + (screenWidth - 30) / 2 + 'px;'"
                lazy-load
                fit="cover"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div class="shuiyin">{{strId}}</div>
            </template>
            <template #text>
              <div class="goods_text van-multi-ellipsis--l2" :style="'width:'+((screenWidth-30)/2-20)+'px;'">{{item.title}}</div>
              <div class="goods_price van-ellipsis" :style="'width:'+((screenWidth-30)/2-20)+'px;'">
                <span style="color: #C8241A;">&yen;</span> {{item.price}}
                <span v-show="parseInt(item.sales) > 0 ? true : false">{{item.sales}}人已购买</span>
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </van-list>
    </div>
    <!-- 列表 横板 -->
    <div class="list_twocontent" v-show="listType == 1 ? false : true">
      <van-list
        v-model="goodsloading"
        :finished="goodsfinished"
        :finished-text="!kongshow && goodsfinished ? '没有更多了' : ''"
        :immediate-check="false"
        @load="getGoodsList"
      >
        <van-grid :gutter="10" direction="horizontal" :column-num="1" :border="false">
          <van-grid-item v-for="(item,index) in goods" :key="item.id" :data-index="index" :data-id="item.id" @click="details(item.id)">
            <template #icon>
              <van-image
                :src="item.thumb"
                lazy-load
                fit="cover"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div class="shuiyin">{{strId}}</div>
            </template>
            <template #text>
              <div class="list_text">
                <div class="goods_text van-multi-ellipsis--l2">{{item.title}}</div>
                <div class="goods_price van-ellipsis">
                  <span style="color: #C8241A;">&yen;</span> {{item.price}}
                  <span v-show="parseInt(item.sales) > 0 ? true : false">{{item.sales}}人已购买</span>
                </div>
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </van-list>
    </div>
    <div class="kong_page" v-if="kongshow">
      <van-empty description="暂无数据" />
    </div>
    <div class="loading" v-if="loadshow">
      <van-loading size="24px" vertical type="spinner">加载中...</van-loading>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Ghslist',
  data(){
    return {
      showSearchBtn: false, // 默认隐藏搜索按钮
      keywords: '', // 关键词
      listType: 1,
      currSize: 10, // 步数
      currOffset: 0, // 偏移
      goodsloading: false, // 是否加载下一批数据
      goodsfinished: false, // 是否加载完毕
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      uid: localStorage.getItem("DK_UID")?localStorage.getItem("DK_UID"):"", // 用户信息
      islogin: localStorage.getItem("DK_ISLOGIN")?localStorage.getItem("DK_ISLOGIN"):false, // 用户信息
      goods: [],
      groupid: 0,
      shareid: 0,
      merchid: 0,
      strId: "",
      kongshow: false,
      loadshow: false,
      iswx: false,
      wxConfig: {
        debug: false, // 开启调试模式 true false
        appId: "wxc465682b705715e5",
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage"
        ], // 必填，需要使用的JS接口列表
        timestamp: 1585098244, // 必填，生成签名的时间戳
        nonceStr: "dfbfwkm1egc", // 必填，生成签名的随机串
        signature: "d8caa8a88d588a8dc5379dbfbde5d875886baed5" // 必填，签名
      },
      sort: 0, // 0 按新品（时间）排序，1 按销量排序，2按价格升序，3按价格降序
      dudaobrandId: 0,
      brandid: 0,
      ghsid: 0,
      showghs: 0,
      ghsInfo: {
        merchname: '店铺'
      }
    }
  },

  mounted() {
    this.brandid = this.$route.query.brandid ? parseInt(this.$route.query.brandid) : 0;
    this.dudaobrandId = this.$route.query.dudaobrandId ? parseInt(this.$route.query.dudaobrandId) : 0;
    this.keywords = this.$route.query.keywords ? this.$route.query.keywords : "";
    this.groupid = this.$route.query.groupid ? parseInt(this.$route.query.groupid) : 0;
    this.sort = this.$route.query.sort ? parseInt(this.$route.query.sort) : 0;
    this.strId = localStorage.getItem("DK_STRID");
    this.ghsid = this.$route.query.ghsid ? parseInt(this.$route.query.ghsid) : 0;
    this.showghs = this.$route.query.showghs ? parseInt(this.$route.query.showghs) : 0;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.loadshow = false;
    this.currOffset = 0;
    this.init();
    console.log(this.dudaobrandId, "this.dudaobrandId");
    
  },
  activated() {
    this.$parent.getmerchname(this.merchid, "产品列表");
    let keywords = '';
    let groupid = 0;
    let sort = 0;
    let dudaobrandId = 0;
    let brandid = 0;
    keywords = this.$route.query.keywords ? this.$route.query.keywords : "";
    groupid = this.$route.query.groupid ? parseInt(this.$route.query.groupid) : 0;
    sort = this.$route.query.sort ? parseInt(this.$route.query.sort) : 0;
    dudaobrandId = this.$route.query.dudaobrandId ? parseInt(this.$route.query.dudaobrandId) : 0;
    brandid = this.$route.query.brandid ? parseInt(this.$route.query.brandid) : 0;
    
    if (keywords != this.keywords || groupid != this.groupid || sort != this.sort || dudaobrandId != this.dudaobrandId || brandid != this.brandid) {
      this.loadshow = false;
      this.currOffset = 0;
      this.keywords = keywords;
      this.groupid = groupid;
      this.sort = sort;
      this.dudaobrandId = dudaobrandId;
      this.brandid = brandid;
      this.init();
    }else {
      this.loadshow = false;
    }
    if (window.isWeixin()) {
      this.iswx = true; 
      setTimeout(() => {
        this.peizhiWxConfig(); // 配置微信分享
      }, 1000);
    }
  },

  methods: {
    init() {
      this.getGoodsList();
      if(this.showghs > 0){
        this.getGHSInfo();
      }
      if (this.merchid > 0) {
        this.setmerchMber();
      }
    },

    // 获取供货商信息
    getGHSInfo(){
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      if (_that.showghs > 0) {
        _that.$axios
          .post(
            _that.$store.state.domain + 'web/goods/ghs_info',
            _that.$qs.stringify({
              ghsid: _that.ghsid
            })
          )
          .then(function(response){
            _that.ghsInfo = response.data.ghs_info
          })
      }
    },

    // list 加载获取产品数据
    getGoodsList(){
      let _that = null;
      _that = this;
      _that.showSearchBtn = false
      _that.goodsloading = true;
      if (_that.currOffset == 0) {
        _that.loadshow = true;
      }
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      if ((this.keywords || this.groupid >= 0) && _that.goodsloading) {
        _that.$axios
          .post(
            _that.$store.state.domain + 'web/goods/get_search',
            _that.$qs.stringify({
              size: _that.currSize,
              offset: _that.currOffset,
              keywords: _that.keywords,
              groupid: _that.groupid,
              merchid: _that.merchid,
              sort: _that.sort,
              dudaobrandId: _that.dudaobrandId,
              brandid: _that.brandid
            })
          )
          .then(function(response){
            _that.loadshow = false;
            
            if (response && response.data) {
              if(_that.currOffset == 0){
                _that.goods = response.data
              }else{
                response.data.forEach((item,index)=>{
                  if (parseInt(item.sales) > 10000) {
                    response.data[index].sales = (item.sales/10000).toFixed(1)+'万'
                  }
                  _that.goods.push(response.data[index]);
                })
              }
              
              if (response.data.length < _that.currSize) {
                _that.goodsfinished = true; // 加载完毕
              }else {
                _that.goodsfinished = false; // 加载完毕
              }
              _that.currOffset += response.data.length;
              
            }else {
              _that.goodsfinished = true; // 加载完毕
            }
            _that.goodsloading = false; // 本次数据加载完成
          })
          .catch(function(error){
            _that.goodsfinished = true; // 加载完毕
          })
      }
    },
    
    // 点击排序按钮
    sortList(num) {
      if (num == 0) {
        this.sort = 0;
      }else if(num == 1) {
        if (this.sort == 1) {
          this.sort = 2;
        }else{
          this.sort = 1;
        }
      }else if(num == 2) {
        if (this.sort == 3) {
          this.sort = 4
        }else {
          this.sort = 3
        }
      }

      this.$router.replace({ name: "List", query: { keywords: this.keywords, groupid: this.groupid, sort: this.sort, shareid: this.shareid, merchid: this.merchid } });
      this.goodsfinished = false;
      this.currOffset = 0;
      this.getGoodsList();
    },

    handleScroll() {
      //方法一
      //var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageXOffset)


      //方法二
      //如果获取不到高度可换成 
      // this.nextTick(()=>{
      //   let top = 0;
      //   top = document.body.scrollTop || document.documentElement.scrollTop || window.pageXOffset
      //   
      // })
      let top = 0;
      top =  Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageXOffset);
      
      return top;
    },
    
    // 点击左侧按钮
    onClickLeft(){

      this.$router.go(-1);
    },
    // 点击右侧按钮
    onClickRight(){
      if (this.listType == 1) {
        this.listType = 2
      }else{
        this.listType = 1
      }
    },
    // 输入框获取焦点
    inputFocus(event){
      window.scrollTo(0,0);
      if (this.keywords) {
        this.showSearchBtn = true
      }else{
        this.$router.replace({ name: "Search", query: { shareid: this.shareid, merchid: this.merchid, dudaobrandId: this.dudaobrandId } })
      }
    },
    // 输入框失去焦点
    inputBlur(event){
      if (!this.keywords) {
        this.showSearchBtn = false
      }
    },
    // 输入框输入文字
    inputText(val){
      this.keywords = val;
      if (this.keywords) {
        this.showSearchBtn = true
      }else{
        // this.showSearchBtn = false
        this.$router.replace({ name: "Search", query: { shareid: this.shareid, merchid: this.merchid } })
      }
    },
    // 点击搜索按钮
    onSearch(){
      console.log('onSearch');
      if (this.keywords) {
        this.goodsfinished = false;
        this.currOffset = 0;
        this.getGoodsList();
      }
    },
    // 输入完内容后点击键盘上的搜索按钮
    enterSearch(val) {
      console.log('enterSearch');
      if (val) {
        this.goodsfinished = false;
        this.currOffset = 0;
        this.getGoodsList();
      }
    },
    // 进入详情页
    details(id){
      this.$router.push({ name: "Details", query: { id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    goghsZiZhi(id){
      console.log(12312313);
      this.$router.push({ name: "Ghsdetail", query: { gshid: id, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
      }
    },
    
    /* 设置分享信息 */
    onShare() {
      let _that = null;
      _that = this;
      const mid = localStorage.getItem("DK_MEMBERID"); // 分享者id
      let title = "大咖货源"; // 分享标题
      let desc = document.title; // 分享简介
      let img = ""; // 分享图片地址
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_merchNme",
          _that.$qs.stringify({
            id: _that.merchid
          })
        )
        .then(res => {
          if (res.data.merchname) {
            title = res.data.merchname;
            desc = res.data.merchname;
          }
          if (res.data.logo) {
            img = res.data.logo;
          }

          window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
            window.wx.updateAppMessageShareData({ 
              title: title, // 分享标题
              desc: document.title, // 分享描述
              link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: img, // 分享图标
              type: "link",
              success: function() {
                console.log("分享设置成功");
              }
            })
          });
        })
    },
    // 分享初始化
    peizhiWxConfig() {
      let _that = null;
      _that = this;
      /* 获取wx初始化配置 */
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_sign",
          _that.$qs.stringify({
            url: encodeURIComponent(window.location.href)
          })
        )
        .then(function(response) {
          _that.wxConfig.timestamp = response.data.timestamp;
          _that.wxConfig.nonceStr = response.data.nonceStr;
          _that.wxConfig.signature = response.data.signature;
          window.wx.config(_that.wxConfig); // wx初始化配置
          setTimeout(() => {
            _that.onShare();
          }, 100)
        })
        .catch(function(error) {
          window.getWx = _that.getWxConfig; // 微信签名过期后调用 外部JS
        });
    },

    /* 获取配置失效 */
    getWxConfig(err) {
      // 如果过期，从html JS 调用重新签名
      if (err.indexOf("invalid signature") != -1) {
        let $this = null;
        $this = this;
        this.$axios
          .post(
            $this.$store.state.domain + "web/wetch/wx_sign",
            $this.$qs.stringify({
              url: encodeURIComponent(window.location.href)
            })
          )
          .then(function(response) {
            this.peizhiWxConfig(); // 配置微信分享
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    }
  }
}
</script>
<style lang="less">
.list{
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #ededed;
  padding-top: 100px;
  padding-bottom: 60px;
  // 头部
  .title{
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    .van-nav-bar__title{
      flex: 1;
      max-width: 100%;
      .van-cell__value{
        flex: 1;
      }
      .van-search{
        padding: 0;
        .van-search__content{
          padding-left: 8px;
        }
        .van-icon{
          color: #999;
        }
      }
    }
    .van-nav-bar__left,
    .van-nav-bar__right{
      position: static;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      padding: 0;
      .van-icon{
        margin: 0 auto;
      }
      .van-button{
        border-radius: 4px;
        font-size: 14px;
      }
      .van-button--danger{
        background-color: #E93B3D;
        border-color: #E93B3D;
      }
    }
    .van-nav-bar__right{
      .van-icon{
        font-size: 20px;
        color: #333;
      }
      .search_text{
        width: 100%;
        text-align: center;
      }
    }
  }
  // 筛选
  .list_per{
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 50px;
    right: 0;
    z-index: 1;
    .van-grid-item__text{
      font-size: 14px;
    }
    .sort_list{
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      .sort_asc{
        display: inline-block;
        overflow: hidden;
        vertical-align: middle;
        height: 10px;
        line-height: 4px;
        width: 12px;
        .van-icon{
          font-size: 8px;
          line-height: 4px;
        }
      }
    }
  }
  .list_per_ghs{
    padding: 0 10px;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 50px;
    right: 0;
    z-index: 1;
    .van-cell {
      padding: 10px 12px;
      text-align: left;
      .van-cell__title {
        flex: 1;
        font-size: 15px;
        font-weight: bold;
        color: #333;
        line-height: 20px;
        padding-left: 10px;
      }
      .van-cell__label {
        font-size: 13px;
        font-weight: normal;
      }
    }
  }
  // 列表
  .list_content,
  .list_twocontent{
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 10px;
  }
  // 双排竖版
  .list_content{
    .van-grid-item__content{
      padding: 0;
      border-radius: 8px;
      overflow: hidden;
      .van-image{
        display: block;
      }
      .goods_text{
        height: 40px;
        line-height: 20px;
        padding: 5px 10px 0;
        font-size: 14px;
        color: #333;
        text-align: left;
      }
      .goods_price{
        padding: 5px 10px 10px;
        font-size: 16px;
        color: #C8241A;
        text-align: left;
        span{
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
  // 横板
  .list_twocontent{
    .van-grid-item__content{
      padding: 10px;
      .van-image{
        width: 80px;
        height: 80px;
        display: block;
        overflow: hidden;
      }
      .list_text{
        flex: 1;
        text-align: left;
        padding-left: 10px;
        height: 80px;
        overflow: hidden;
        position: relative;
        border-radius: 2px;
        .goods_text{
          line-height: 20px;
          font-size: 14px;
        }
        .goods_price{
          height: 20px;
          line-height: 20px;
          position: absolute;
          left: 10px;
          bottom: 0;
          font-size: 16px;
          color: #C8241A;
          text-align: left;
          span{
            color: #999;
            font-size: 12px;
          }
        }
      }
    }
  }
  // 水印
  .shuiyin{
    padding: 2px;
    background-color:#fff;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #808080;
    opacity: 0.5;
    font-size: 14px;
  }
  .loading {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -30px 0 0 -26px;
      color: #fff;
      .van-loading__text{
        color: #fff;
      }
    }
  }
  .red {
    color: #ff0000;
  }
}
</style>
